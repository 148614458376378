
// import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal, GiHammerNails } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";
// import { IoMdHammer } from "react-icons/io";
function HeroVideo({ title, texts, bgImg, bgImg1, bgImg2, bgImg3 }) {
  // const { rpdata } = useContext(GlobalDataContext);
  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <div>
      <div className=" w-full h-screen pic-wrapper flex lg:justify-center justify-start pt-[30vh]">
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-[4] relative w-4/5 mx-auto h-full flex md:flex-row flex-col justify-center items-center md:pb-[170px] md:pt-[250px] pt-[300px] pb-[100px]md:text-start text-center text-white">
          <div className="w-full text-center lg:text-start p-4 lg:w-[60%] ">
            <h1 className="text-[30px] md:text-[3em] lg:text-[3em]">{title}</h1>
            <p className="">{texts}</p>
            <ButtonContent btnStyle="three" />
          </div>
          <div className="relative w-[40%]">
            <img src={bgImg} alt="img1" className="hidden lg:flex w-[300px] h-auto  border-[8px] mt-10" />
            <img src={bgImg2} alt="img1" className="absolute hidden lg:flex w-[300px] h-[400px] -top-10 -right-0  border-[8px] -z-[2]" />

          </div>
        </div>
        {/* <div className="w-[30%] 0 h-full bg2 md:absolute md:block right-0 -z-[0] hidden">

                </div> */}
      </div>

    </div>
  );
}
export default HeroVideo;
